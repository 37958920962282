<template>
	<PopupLayer
		v-if="is_view"
	>
		<template
			v-slot:body
		>
			<div class="width-480 ma-auto radius-20">
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h5 class="color-white">카르텔 권한 &gt; {{ member.admin_nickname }}</h5>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>

				<div
					v-if="member.permission"
					class="bg-white pa-20"
				>
					<div class="under-line pb-10">
						<div class="bright_checks">
							<input
								v-model="is_all"
								type="checkbox" id="all"
								@change="setPermission"
							>
							<label for="all" class="all_check h5"> 모든 권한을 가집니다. </label>
						</div>
					</div>

					<div
						class="mt-10"
					>
						<h5 class="">카르텔 관리 (회원 승인 및 사용정지 권한)</h5>
						<ul class="">
							<li class="mt-10">
								<div class="bright_checks">
									<input
										v-model="member.permission['cartl_admin_mangement']"
										type="checkbox" id="ct1"
										@change="isAll(member.permission['cartl_admin_mangement'])"
									>
									<label for="ct1">운영자 관리 (임명 및 권한, 해임)</label>
								</div>
							</li>
							<li class="mt-10">
								<div class="bright_checks">
									<input
										v-model="member.permission['cartl_member_mangement']"
										value="CA01700002"
										type="checkbox" id="ct2"
										@change="isAll(member.permission['cartl_member_mangement'])"
									>
									<label for="ct2">회원 관리 (회원 승인 및 사용정지)</label>
								</div>
							</li>
						</ul>
					</div>

					<hr class="under-line mt-10"/>

					<div class="mt-10">
						<h5>등록 관리</h5>
						<ul class="">
							<li class="mt-10">
								<div class="bright_checks">
									<input
										v-model="member.permission['cartl_notice_mttrs_reg']"
										type="checkbox" id="ct3"
										@change="isAll(member.permission['cartl_notice_mttrs_reg'])"
									>
									<label for="ct3">공지사항</label>
								</div>
							</li>
							<li class="mt-10">
								<div class="bright_checks">
									<input
										v-model="member.permission['cartl_board_creation']"
										type="checkbox" id="ct4"
										@change="isAll(member.permission['cartl_board_creation'])"
									>
									<label for="ct4">게시판 생성</label>
								</div>
							</li>
						</ul>
					</div>

					<hr class="under-line mt-10"/>

					<div class="mt-10">
						<h5>멤버 등급 조건 관리</h5>
						<ul class="">
							<li
								class="mt-10"
							>
								<div class="bright_checks">
									<input
										v-model="member.permission['cartl_member_grade_mangement']"
										type="checkbox" id="ct13"
										@change="isAll(member.permission['cartl_member_grade_mangement'])"
									>
									<label for="ct13">멤버 등급 조건 관리</label>
								</div>
							</li>
						</ul>
					</div>

					<hr class="under-line mt-10"/>

					<div class="text-center mt-10">
						<button
							class="btn-inline btn-primary radius-20"
							@click="postPermission"
						>저장</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia089'
		, components: {PopupLayer}
		, props: ['cartel', 'user', 'member']
		, data: function(){
			return {
				program: {
					name: '언더보스 권한 선택'
					, title: '언더보스 권한'
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, items_board_config: []
				, is_all: false
				, is_view: false
			}
		}
		, methods: {
			getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_manager_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.member.admin_member_number
						}
						, type: true
					})
					if(result.success){
						this.$set(this.member, 'permission', result.data)
						for(let [k, v] of Object.entries(this.member.permission)){
							this.$log.console(k, v)
							if(k.indexOf('_fg') > -1){
								this.member.permission[k.replace('_fg', '')] = v == 'Y' ? true : false
							}
						}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
				}finally {
					this.is_view = true
					this.$bus.$emit('on', false)
				}
			}
			,getBoardList: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.items_board_config = result.data.board_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setPermission: function(){
				let t = this.$codes.permission_manager
				let d = this.is_all

				for(let item of t){
					this.member.permission[item] = d
				}
				for(let item of this.items_board_config){
					this.member.permission[item.code] = d
				}
			}
			, isAll: function(){
				let t = this.$codes.permission_manager

				for(let item of t){
					this.is_all = true
					if(!this.member.permission[item]){
						this.is_all = false
						break
					}
				}
			}
			, postPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.member.admin_member_number
							, cartl_all_authority_fg: this.is_all ? 'Y' : 'N'
							, cartl_admin_mangement_fg: this.member.permission.cartl_admin_mangement ? 'Y' : 'N'
							, cartl_member_mangement_fg: this.member.permission.cartl_member_mangement ? 'Y' : 'N'
							, cartl_notice_mttrs_reg_fg: this.member.permission.cartl_notice_mttrs_reg ? 'Y' : 'N'
							, cartl_board_creation_fg: this.member.permission.cartl_board_creation ? 'Y' : 'N'
							, cartl_member_grade_mangement_fg: this.member.permission.cartl_member_grade_mangement ? 'Y' : 'N'
						}
						, type: true
					})
					if(result.success){
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch(e){
					this.$log.console('postPermission', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, async created() {
			this.$log.console('this.member', this.member)
			await this.getPermission()
		}
		,watch: {
			'member.permission': {
				deep: true
				, handler: function(call){
					this.$log.console('member.permission', call)
					let d = false
					let t = this.$codes.permission_manager
					for(let i in t){
						this.$log.console(i, t[i], call[t[i]])
						if(!call[t[i]]){
							this.is_all = false
							d = false

							break
						}else{
							d = true
						}
					}

					if(d){
						this.is_all = true
					}
				}
			}
		}
	}
</script>